const dataVisColors = [
  "blue50", "purple90", "purple50", "red90", "red60", "cyan80",
  "magenta50", "blue70", "green60", "cyan90", "cyan60", "orange90",
  "yellow70", "purple70", "orange40", "red70", "yellow50", "blue80"];

export const dataVisColorVars = _ => {
  const style = getComputedStyle(document.body);
  return dataVisColors.map(color =>
    style.getPropertyValue(`--mdb-data-vis-${color}`));
};

export const dataVisOneColorVar = _ => {
  const style = getComputedStyle(document.body);
  return [style.getPropertyValue(`--mdb-data-vis-blue50`)];
};

export function getColorOfTax(dsSigla) {
  const taxColor = {
    PIS: 'purple50',
    PISRF: 'blue80',
    COFINS: 'orange90',
    COFINSRF: 'yellow50',
    ICMS: 'orange40',
    ICMSA: 'red60',
    IPI: 'magenta50',
    IRRF: 'cyan90',
    ISS: 'green60',
    IBSUF: 'blue50',
    IBSMUN: 'blue70',
    IBS: 'blue50',
    CBS: 'purple90',
    IS: 'cyan60'
  };

  const style = getComputedStyle(document.body);
  return style.getPropertyValue(`--mdb-data-vis-${taxColor[dsSigla]}`)
}