import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="accounts--simulations--tax-simulation"
export default class extends Controller {

  static targets = [
    "nopCode",
    "btnRefineQuery",
    "refineContent",
    "hdnRefineQueryEnabled",
    "ncmCode",
    "fieldsFromServiceDocumentFiscal",
    "cdMunicipio",
    "cdServicoLocal",
    "cdServicoOrigem",
    "cdServicoDestino",
    "ncmId",
    "inconclusivos",
    "btnInconclusivos",
    "conclusivos",
    "btnConclusivos",
    "uf",
    "calculaReforma",
    "showTaxReformProjection",
    "btnCalculate"
  ];

  submitFormWithFetch(ev) {
    ev.preventDefault();

    document.getElementById("tax-simulation-results-loading").classList.remove("d-none");
    document.getElementById("simulation_results").innerHTML = "";
    window.scrollTo({top: 0, behavior: "smooth"});

    const form = ev.target; // O formulário que disparou o evento
    const formData = new FormData(form);
    this.enableButtonCalculate(true);

    fetch(form.action, {
      method: form.method,
      body: formData
    }).then(response => {
      // Convert the chunk value to a string
      response.text().then(resp => {
        const responseText = resp.replaceAll("data: ", "");
        const parser = new DOMParser();

        const turboStream = parser.parseFromString(responseText, "text/html");
        document.body.appendChild(turboStream.documentElement);

        this.enableButtonCalculate(false);
        this.enableInputFields(this.nopCodeTarget.value);
      }).finally(() => {
        document.getElementById("tax-simulation-results-loading").classList.add("d-none");
      });

      this.enabledShowTaxReformProjection();
    });
  }

  enableButtonCalculate(enable) {
    this.btnCalculateTarget.innerText = !enable ? "Calcular" : "Calculando...";
    this.btnCalculateTarget.disabled = enable;
  }

  connect() {
    this.enableInputFields(this.nopCodeTarget.value);
    this.enabledShowTaxReformProjection();
  }

  onNopChange(event) {
    this.enableInputFields(event.target.value);
  }

  onCalculaReformaChange() {
    this.enabledShowTaxReformProjection();
  }

  enabledShowTaxReformProjection() {
    if (!this.calculaReformaTarget.checked) {
      this.showTaxReformProjectionTarget.checked = false;
      this.showTaxReformProjectionTarget.disabled = true;
    } else {
      this.showTaxReformProjectionTarget.disabled = false;
    }
  }

  onShowTaxReformProjection(event) {
    if (this.showTaxReformProjectionTarget.checked && this.calculaReformaTarget.checked === false) {
      this.calculaReformaTarget.checked = true;
    }
  }

  listCityServiceCodes(url) {
    return new Promise((resolve, reject) => {
      Rails.ajax({
        url: url,
        type: "GET",
        cache: false,
        success: (result) => {
          resolve(result);
        },
        error: (result) => {
          reject(result);
        }
      });
    });
  }

  clearSubItemServicos(cdServico) {
    while (cdServico.options.length > 0) {
      cdServico.remove(0);
    }
  }

  getSubItemFieldByName(name) {
    if (name.endsWith("emitente"))
      return this.cdServicoOrigemTarget;
    else if (name.endsWith("destinatario"))
      return this.cdServicoDestinoTarget;

    return this.cdServicoLocalTarget;
  }

  onCdMunicipioChange(event) {
    let subItemService = this.getSubItemFieldByName(event.target.name);
    this.clearSubItemServicos(subItemService);
    let city_id = event.target.value;

    if (city_id === "") return;
    let url = "/cities/" + `${city_id}` + "/city_service_codes/list";

    this.listCityServiceCodes(url).then(response => {
      if (response !== undefined) {
        response.forEach(x => {
          subItemService.add(new Option(x.code, x.code));
        });
      }
    });
  }

  isServiceDocumentFiscal(nop_code) {
    // 102 - Prestação de Serviço
    return +nop_code === 102;
  }

  enableInputFields(nop_code) {
    let isServiceDocumentFiscal = this.isServiceDocumentFiscal(nop_code);
    this.enableDivs(this.fieldsFromServiceDocumentFiscalTargets, isServiceDocumentFiscal);
    this.enableDivs(this.ufTargets, !isServiceDocumentFiscal);
    this.enableDiv(this.ncmCodeTarget, !isServiceDocumentFiscal);
  }

  enable() {
    this.enableDiv(this.refineContentTarget, this.refineContentTarget.classList.contains("d-none"));
  }

  showMore(event) {
    if (event.target.name === "btnconclusivos")
      this.showMoreContent(event, this.conclusivosTarget);
    else
      this.showMoreContent(event, this.inconclusivosTarget);
  }

  showMoreContent(event, element) {
    let show = !element.classList.contains("d-none");
    this.changeTextBtnShowMore(event, show);
    this.enableDiv(element, !show);
  }

  changeTextBtnShowMore(element, show) {
    if (show) {
      element.target.innerText = "Mostrar mais";
      return;
    }
    element.target.innerText = "Mostrar menos";
  }

  enableDiv(element, enable) {
    if (enable)
      element.classList.remove("d-none");
    else
      element.classList.add("d-none");
  }

  enableDivs(element, enable) {
    element.forEach(div => {
      this.enableDiv(div, enable);
    });
  }
}